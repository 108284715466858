/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/11/202 
 * @modify 12/11/2020 
 * @desc Exporting all the components from /src/components 
 */

import React from 'react';
import { Divider, Grid, ListItem, Tooltip, List, ListItemText, Typography, ListItemSecondaryAction ,Popover} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import { InsideFolder } from './';
import { PropertiesContext, RightSideNav } from '../../../../contexts';
import InputRoundedIcon from '@material-ui/icons/InputRounded';

import axios from 'axios';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { withAllContexts } from "../../../../HOCs";
import environment from '../../../../config';

const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
        padding: "0px",
        display: "block",
        
        "webkitTouchCallout": "none",
        "webkitUserSelect": "none",
        "khtmlUserSelect": "none",
        "mozUserSelect": "none",
        "msUserSelect": "none",
        "userSelect": "none"
    },
    listRoot: {
        flexGrow: 1,
        padding: "0px",
        "& :hover": {
            background: "#e8e8e8",
            borderRadius: "0px"
        }
    },
    listItem: {
        padding: "7px 18px 7px 18px",
        borderRadius: "0px"
    },
    listItemText: {
        margin: "0px"
    },
    iconLeft: {
        marginLeft: "5px"
    },
    listicon: {
        height: "24px",
        '& svg': {
            width: "20px",
            color: "#5f5d5d"
        }
    },
    emptyData: {
        padding: "10px",
        fontSize: "12px",
        textAlign: "center"
    },
    fieldData: {
        display: "grid",
        gridTemplateColumns: "1fr 109px",
        width: "87%"
    },
    fieldDatadimention: {
        display: "grid",
        gridTemplateColumns: "26px 36px 1fr",
        width: "93%"
    },
    addIcon: {
        marginRight: "10px"
    },
    marginAuto: {
        margin: "auto"
    },
    relationshipicon: {
        width: "15px",
        height: "15px",
        margin: "auto auto auto 7px"
    },
    // listItemPad: {
    //     padding: "7px 18px 7px 36px",
    //     borderRadius: "0px"
    // },
    actionIcons: {
        cursor: "pointer"
    },
    disableField:{
        pointerEvents: "none",
        cursor: "auto"
    },
    normalField:{
        cursor: "pointer"
    }

}));

class EntityFieldList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openCollapse: []
        }
        this.openProperties = this.openProperties.bind(this);
    }

    openList(id, context) {
     
        let { openCollapse } = this.state;
        if (openCollapse.indexOf(id) > -1) {
            if (openCollapse.length === 1) {
                openCollapse = [];
            } else {
                openCollapse.splice(1, openCollapse.indexOf(id))
            }
        } else {
            openCollapse.push(id)
        }
        context.setRightSideNav({
            isopen: true
        });
        this.setState({ openCollapse })
        this.forceUpdate()
    }
    openProperties = async (field, fieldIndex, tableIndex) => {
         
        let { data, rightNav } = this.props;
         
        let context = this.context;
        let relationDataField = null;
        // if (field && field?.properties?.datatype && Object.keys(field?.properties?.dataTypeProperties ?? {}).length <= 0) {
        //     await axios
        //         .post(`${environment.api_url}/api/read_documents`, {
        //             "db_name": `${environment.database}`,
        //             "entity": `${environment.qdm_property_entity}`,
        //             "filter": `${environment.qdm_property_entity}.datatype=='${field.properties?.datatype.toLowerCase()}' `,
        //             "return_fields": `{${environment.qdm_property_entity}}`
        //         }
        //         ).then(response => {
        //             // let data = converData(response.data);
        //             field.properties.dataTypeProperties = response.data.result[0];
        //             // 
        //         })
        //         .catch(err => {
        //         });
        // }
        rightNav.setRightSideNav({
            ...rightNav,
            isopen: true
        });
        context.setProperties({
            ...context,
            isopen: !context.isopen,
            editFieldData: field,
            editTable: data,
            tableIndex,
            fieldIndex,
            relationDataField
        })
         
    }
    deleteField = (e, val) => {
        const { confirmationContext, data,leftContext } = this.props;
        const { setConfirm } = confirmationContext;
        if(leftContext?.list){
            let takeKeys= Object.keys(leftContext.list);
            for(let item of takeKeys){
                let isCheck = false;
                for(let _item of leftContext.list[item]){
                    if(_item.fields[0].name ==='rule_params'){
                        let filterRuleCollection = _item.fields[0]?.properties.fields.filter(_f=>_f.name===val.attributes?.table);
                        if(filterRuleCollection?.length>0){
                     

                                 for(let __item of filterRuleCollection[0]?.properties?.fields){
                                     if(__item?.name===val.name){
                                        this.props.alert.setSnack({
                                            open:true,
                                            msg:"This field created in rules",
                                            severity:'info'
                                        });
                                        isCheck = true;
                                        break;
                                     }

                                 }
                        }
                        if(isCheck){
                            break;
                        }
                   
                    }
                }
                if(isCheck){
                    return;
                    // break;
                }
            }
        }
        let Rules = val?.properties?.Sequence ? Object.values(val?.properties?.Sequence).filter(_=>_.toString().indexOf('Rules/')!==-1): '';
        let ruleMessage = 'Are you sure delete this field ?';
        if(Rules.length>0){
            ruleMessage = `This field contain Rules,Are you sure delete this field ?`
        }
        setConfirm({
            ...confirmationContext,
            open: true,
            title: "Confirmation",
            dialog: ruleMessage,
            type: 2,
            entity: data,
            data: val
        })
    }
    render() {
        let { classes, list, emptyContent, openEdit, data, handleRealatioShipClick , handleGroupRealatioShipClick, insideVlaue, isTemplate, openRef, noEditAction, tableIndex } = this.props;
        let { openCollapse } = this.state;
        

 
        return (
            <RightSideNav.Consumer>
                {
                    rightSidenav => (
                        <Grid container className={classes.root}  >
                            {
                                
                                list && list?.map((val, index) => {
                                      
                                    return <React.Fragment key={index}>
                                        {
                                            val?.name!=='rule_params' ? 
                                            <>
                                         
                                            <List                                                                                                                                                                                                                           
                                            component="nav"
                                            aria-labelledby="nested-list-subheader"
                                            className={  val?.name==="_id" ? classes.disableField :classes.normalField +  '  ' +classes.listRoot + "  testing" }
                                        >
                                             {/* <Popover
                                             id={'add_entity'}
                                             open={true}
                                             anchorEl={true}
                                            //  onClose={this.handleClose}
                                             anchorOrigin={{ vertical: 'center', horizontal: 'right', }}
                                             transformOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                                            > */}
                                            <ListItem className={insideVlaue ?
                                                classes.listItemPad + '  relation-connection' + val?.attributes?.id :
                                                classes.listItem + '  relation-connection' + val?.attributes?.id} id={val?.attributes?.id}>
                                                <div 
                                                 id={val?.attributes?.id} onClick={val?.fieldType === 'd' ? () => { this.openList(val?.attributes?.id, rightSidenav); this.openProperties(val, index, tableIndex); } : () => { this.openProperties(val, index, tableIndex) }} className={val?.fieldType !== 'd' ? classes.fieldData : classes.fieldDatadimention}
                                                 >
                                                    {val?.fieldType === 'd' && <React.Fragment>
                                                        {openCollapse.indexOf(val?.attributes?.id) > -1 ? <ArrowDropDownIcon className={classes.marginAuto} /> : <ArrowRightIcon className={classes.marginAuto} />}
                                                        <DeviceHubIcon className={classes.marginAuto} />
                                                    </React.Fragment>}
                                                    
                                                    <ListItemText secondary={
                                                       
                                                        <Typography >
                                                            
                                                            {
                                                                val?.name?.length<=10 ? <span>{val?.name}</span> :
                                                                <abbr title={val?.name} style={{textDecoration: "none"}}>
                                                                      <span>{val?.name?.slice(0,8)}...</span>
                                                                </abbr>
                                                            }
                                                         
                                                 
                                                            {
                                                            (val?.fieldType !== 'd' && !isTemplate) &&
                                                            
                                                                 <abbr title={'Relationship'} style={{textDecoration: "none"}}>
                                                                     
                                                                      <InputRoundedIcon onClick={(e) => handleRealatioShipClick(e, val)} className={classes.relationshipicon + ' relationShip' + val?.attributes?.id} />
                                                                  </abbr>

                                                            }

                                                           

                                                        </Typography>
                                                    } />
                                                    {(val?.fieldType !== 'd' && val?.attributes?.reference && openRef) && 
                                                    // <Tooltip title={val?.attributes.reference ? val?.attributes.reference : val?.properties?.datatype} placement="top">
                                                        <a><ListItemText
                                                            onClick={() => openRef(val?.attributes?.reference)}
                                                            secondaryTypographyProps={{ style: { color: "blue", cursor: "pointer", overflow: "hidden", textOverflow: "ellipsis", width: "81px", textAlign: "right", float: "right", marginRight: "20px", padding: "0px 13px", borderRight: "1px solid #d6d6d6", borderLeft: "1px solid #d6d6d6" } }}
                                                            secondary={val?.attributes?.reference ? val?.attributes?.reference : val?.properties?.datatype}
                                                        />
                                                        </a>
                                                    // {/* </Tooltip> */}
                                                    }
                                                    {(val.fieldType !== 'd' && (!(val?.attributes?.reference) || !openRef)) && 
                                                    // <Tooltip title={val?.properties?.datatype} placement="top">
                                                        <ListItemText
                                                        secondaryTypographyProps={{ style: { overflow: "hidden", textOverflow: "ellipsis", width: "81px", textAlign: "right", float: "right", marginRight: "20px", padding: "0px 13px", borderRight: "1px solid #d6d6d6", borderLeft: "1px solid #d6d6d6" } }}
                                                        secondary={val?.properties?.datatype}
                                                    />
                                                    // {/* </Tooltip> */}
                                                    }
                                                </div>
                                                {!noEditAction && <ListItemSecondaryAction>
                                                    {val?.fieldType === 'd' && <AddCircleOutlineIcon className={classes.addIcon + ' addEntityField' + val?.attributes?.id} onClick={(e) => openEdit(e, val, true)} color="secondary" />}
                                                    <abbr title={'Edit'} style={{textDecoration: "none"}}>
                                                       <EditOutlinedIcon className={'addEntityField' + val?.attributes?.id + " " + classes.actionIcons} onClick={(e) => openEdit(e, val)} />

                                                    </abbr>
                                                    <abbr title={'Delete'} style={{textDecoration: "none"}}>
                                                          <DeleteOutlineIcon className={classes.actionIcons} onClick={(e) => this.deleteField(e, val)} />

                                                    </abbr>
                                                </ListItemSecondaryAction>}
                                            </ListItem>
                                            {/* </Popover> */}
                                        </List>
                                        <Divider />
                                        
                                        {openCollapse.indexOf(val?.attributes?.id) > -1 && <InsideFolder noEditAction={noEditAction} openRef={openRef} handleRealatioShipClick={handleRealatioShipClick} handleGroupRealatioShipClick = {handleGroupRealatioShipClick} tableIndex={tableIndex} isTemplate={isTemplate} data={data} openEdit={openEdit} list={val?.properties?.fields ? val?.properties?.fields : []} />}
                                        
                                        </>
                                        : ''
                                    }
                                    </React.Fragment>
                                })
                            }
                            {
                                (!list || list === 0) && <div>
                                    <Typography className={classes.emptyData}>{emptyContent ? emptyContent : `No Data`}</Typography>
                                </div>
                            }
                        </Grid >
                    )
                }
            </RightSideNav.Consumer>

        )
    }
}

EntityFieldList.contextType = PropertiesContext;
// EntityFieldList.contextType  = RightSideNav;
export default withStyles(useStyles)(withAllContexts(EntityFieldList));