/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/14/202 
 * @modify 12/14/2020 
 * @desc Exporting all the components from /src/components 
 */

import React from 'react';
import { Grid, Typography, Avatar, Divider, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { RightSideNav } from '../../../contexts';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withAllContexts } from '../../../HOCs';
import { JsonCheckerContext } from '../../../contexts';
import { converData } from '../../../contexts/entity/getEntities';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import axios from 'axios';
import { Alerts } from '../../../components'
import environment from '../../../config';
const useStyles = ((theme) => ({
    root: {
        flexGrow: 1,
        padding: "8px 8px"
    },
    avatar: {
        width: "115px",
        top: "42px",
        height:36,

        position: "absolute",
        background: "#white",
        color: "#0b78fa",
        borderRadius: "10px",

    },
    button:{fontWeight:"500"},
    avatar1: {
        width: "125px",
        top: "104px",
        position: "absolute",
        background: "#0b78fa !important",
        color: "white",
        borderRadius: "10px"
    },
    rightNav1: {
        right: "224px",
    },
    rightNav2: {
        right: "15px",
    },
    rightNav3: {
        right: "317px",
    },
    viewVersion: {
        color: "white",
        fontSize: "14px",
        textTransform: "capitalize",
        padding: "0px",
        fontWeight: "400"
    }
}));
class HideCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            snackopen: false,
            snackmsg: 'Please select field first',
            snacktype: 'error',
            saveopen: false,
            property: "",
            autoHideDuration: 2000,
            isSave: true,
            isLock: false
        }
    }
    async componentDidMount() {
        if (this.props.data && this.props.data.length > 0) {
            let params = new URLSearchParams(this.props.data)
        let payload = {
            db_name: `${environment.database}`,
            entity: "ScreenLock",
            metadata_dbname: `ATP_Platform`,
            filter: `ScreenLock.metadataid=='${params.get('metadata_id')}'`,
            return_fields: `{ScreenLock}`
        }
        let ScreenLock_details = {};
        await axios
            .post(`${environment.api_url}/api/read_documents`, payload)
            .then(async (response) => {
                if (response?.data?.Code === 201) {
                    ScreenLock_details = response?.data?.result;
                } else {
                }
            })

            if(ScreenLock_details.length > 0)
            {

        const Result = ScreenLock_details[ScreenLock_details.length - 1];
        if (Result.ScreenLock.IsLocked) {
            this.setState({ isLock: true })
            sessionStorage.setItem("Is_Lock", true)
        }
        else {
            this.setState({ isLock: false })
            sessionStorage.setItem("Is_Lock", false)
        }
    }
}

    }
    Open(context) {

        context.setRightSideNav({
            isopen: !context.isopen
        });

    }


    LockScreen = async () => {

        let payload = {
            db_name: `${environment.database}`,
            entity: "projectvstools",
            filter: `projectvstools.metadataid=='${sessionStorage.getItem("metadataId")}'`,
            return_fields: `{projectvstools}`
        }
        let project_details = {};
        await axios
            .post(`${environment.api_url}/api/read_documents`, payload)
            .then(async (response) => {
                if (response?.data?.Code === 201) {
                    project_details = response?.data?.result[0]?.projectvstools;
                } else {
                    this.setState({
                        saveopen: true,
                        snackmsg: response.data,
                        snacktype: 'success',
                        autoHideDuration: 1000,
                        isLock: response.data.Result[0].properties.doc.IsLocked
                    })
                }
            })
        var base64Url = sessionStorage.getItem('token').split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        let getTokenDetails = JSON.parse(jsonPayload);
        let datass = {
            db_name: `${environment.database}`,
            entity: 'ScreenLock',
            is_metadata: false,
            // metadataId: 'aa29f2d7-f02e-4125-a73b-f6b9fdc9573d',
            // metadata_dbname: `ATP_Platform`,
            doc: {

                ProjectId: `${sessionStorage.getItem("projectId")}`,
                clientId: `${sessionStorage.getItem("clientId")}`,
                IsLocked: !this.state.isLock,
                metadataid: sessionStorage.getItem('metadataId'),
                userId: `${getTokenDetails.preferred_username}`,
                userName: `${getTokenDetails.given_name}`,
                toolName: `${project_details.metadataname}`,
                toolId: `${project_details.toolid}`,
                screenId: `${sessionStorage.getItem("screenId")}`,
            }
        }
        let data = JSON.stringify([datass])
        var config = {
            method: 'post',
            url: `${environment.api_url}/api/upsert_document`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        await axios(config)
            .then(async response => {
                if (response?.data?.Code === 201) {

                    if (response.data.Result.length > 0) {

                     if(response.data.Result[0].properties.doc.IsLocked)
                     {

                        if(response.data.Result[0].properties.doc.userId === getTokenDetails.preferred_username)
                        sessionStorage.setItem("Is_Lock", false)
                        if (response.data.Result[0].properties.doc.IsLocked) {
                            this.setState({
                                saveopen: true,
                                snackmsg: 'Entity Locked successfully',
                                snacktype: 'success',
                                autoHideDuration: 1000,
                                isLock: response.data.Result[0].properties.doc.IsLocked
                            })
                        }

                        else{
                            sessionStorage.setItem("Is_Lock", true)

                        }
                    }
                        else {
                            this.setState({
                                saveopen: true,
                                snackmsg: 'Entity Un Locked successfully',
                                snacktype: 'success',
                                autoHideDuration: 1000,
                                isLock: response.data.Result[0].properties.doc.IsLocked
                            })
                        }
                    }
                }
                else {
                    sessionStorage.setItem("Is_Lock", false)
                    this.setState({
                        saveopen: true,
                        snackmsg: response.data.Errormsg,
                        snacktype: 'error',
                        autoHideDuration: 1000,
                        isSave: true

                    })
                }
            })

    }
    closeSnackBar = () => {
        this.setState({ snackopen: false, saveopen: false })
    }
    resetVersion() {
        let context = this.context;
        let { leftContext } = this.props;
        let { setEntity, setRelation, drawLine, setCount, leftEntity, relation } = leftContext;
        let value = sessionStorage.metaId;
        axios
            .post(`${environment.api_url}/api/get_schema`, {
                "filter": {
                    "columname": "metadataId",
                    "operator": "equals",
                    "value": value
                },
                metadata_dbname: environment.database
            }
            ).then(response => {
                let data = converData(response.data);
                setEntity({
                    ...leftEntity,
                    list: data.entity
                });
                setRelation({
                    ...relation,
                    list: data.relation
                });
                let { count } = drawLine
                setCount({
                    ...drawLine,
                    count: count + 1
                })
            })
            .catch(err => {
            });
        context.setJsonChecker({
            ...context,
            viewVersion: ""
        })

    }
    render() {
        let context = this.context;
        let { classes } = this.props;
        let { snackopen, snackmsg, snacktype, saveopen, autoHideDuration, isSave, isLock } = this.state
        return (
            <RightSideNav.Consumer>
                {

                    rightSideNav => (
                        <React.Fragment>
                            {saveopen && (
                                <Alerts
                                    severity={snacktype}
                                    open={saveopen}
                                    vertical={'top'}
                                    horizontal={'right'}
                                    msg={snackmsg}
                                    autoHideDuration={autoHideDuration}
                                    onclose={() => this.closeSnackBar()}
                                />
                            )}
                            <Grid  container className={classes.root + ' ' + classes.avatar + ' ' + `${classes.rightNav1}`} >
                            <Grid item md={8}>
                                    <Typography style={{fontWeight:450,position:'fixed',top:"110px",right:"283px"}}>
                                         Full view
                                        </Typography>
                                    </Grid>
                                    <Grid item md={4}>
                                         <FormControlLabel style={{ height: 10 ,position:'fixed',top:"118px"}}
                                             control={
                                                 <Switch
                                                     checked={!rightSideNav.isopen}
                                                     onChange={() => this.Open(rightSideNav)}
                                                     name="checkedB"
                                                     color="default"
                                                     size="small"
                                                    //  style={{marginTop:"82px"}}
                                                 />
                                             }
 
                                         />
                                     </Grid>

                            </Grid>
                            <Grid container className={classes.root + ' ' + classes.avatar1 + ' ' + `${classes.rightNav2}`} >
                                <Grid container>
                                    <Grid item md={6} style={{ top:"110px", }}>
                                        {isLock? "Un Lock" : "Lock"}
                                    </Grid>
                                    <Grid item md={4}>
                                        <FormControlLabel style={{ height: 10 }}
                                            control={
                                                <Switch
                                                    checked={isLock}
                                                    onChange={this.LockScreen}
                                                    name="checkedB"
                                                    color="#131947"
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                context?.viewVersion?.length > 0 ?
                                    <Grid container className={classes.root + ' ' + classes.avatar + ' ' + `${rightSideNav.isopen ? classes.rightNav3 : classes.rightNav2}`} style={{ top: 152, padding: 5, width: 39, borderRadius: "50%", height: '37px', background: "white" }}>

                                        <Grid container>
                                            <Grid item md={12} style={{ textAlign: "center" }}>
                                                <Button onClick={() => this.resetVersion()} className={classes.viewVersion} style={{ minWidth: 0 }}><RotateLeftIcon style={{ color: "black" }} /></Button>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    : ""
                            }
                        </React.Fragment>
                    )
                }
            </RightSideNav.Consumer>

        )
    }
}
// HideCard.contextType = RightSideNav;
HideCard.contextType = JsonCheckerContext;
export default withStyles(useStyles)(withAllContexts(HideCard));