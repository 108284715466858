import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Divider, Drawer, Button ,DialogActions,DialogContent,DialogTitle,Dialog,List,ListItem,DialogContentText} from '@material-ui/core';

// import { Grid, Typography, Paper, Divider, Drawer } from '@material-ui/core';
import { AddEntity, EntityFieldList, ListCard, AddField, CustomPopover, AddRelationShip } from '../../';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { withAllContexts } from '../../../HOCs';
import { RightSideNav, } from '../../../contexts'
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import InputRoundedIcon from '@material-ui/icons/InputRounded';
import ListAltIcon from '@material-ui/icons/ListAlt';
import SaveAltIcon from '@material-ui/icons/Save';
import PublishAltIcon from '@material-ui/icons/Publish';
import { removeData, defaultFieldAdd } from "../../../functions/common";
import { relationSplit, grouprelationSplit } from '../../../functions';
import environment from '../../../config';
import { parse, stringify } from 'flatted';
import { Alerts } from '../../../components';
import { converData } from '../../../contexts/entity'
import axios from 'axios';
import { isLastDayOfMonth } from 'date-fns';
import CircularProgress from '@material-ui/core/CircularProgress';
import Stack from '@mui/material/Stack';
const useStyles = ((theme) => ({
    paper: {
        // padding: "15px",
        cursor: "auto",
        borderRadius: "9px",
        width:"360px",
    },
    title: {
        fontWeight: 600,
        display: "inline-block"
    },
    titleCard: {
        cursor: "move",
        color: "white",
        // borderRadius: "9px",
        borderTopLeftRadius: "9px",
        borderTopRightRadius: "9px",
        padding: "10px 20px"
    },
    entityEditIcon: {
        width: "30px",
        height: "18px",
        marginLeft: "3px",
        cursor: "pointer"
    },
    status: {
        float: 'right',
        "webkitTouchCallout": "none",
        "webkitUserSelect": "none",
        "khtmlUserSelect": "none",
        "mozUserSelect": "none",
        "msUserSelect": "none",
        "userSelect": "none"
    },
    addText: {
        display: "inline"
    },
    notificationIcon: {
        marginLeft: "5px",
        background: "#c3c3c7",
        color: "white",
        borderRadius: "50%",
        width: "18px",
        height: "18px !important"

    },
    notificationFont: {
        fontSize: "12px",
        fontWeight: "bold",
        textAlign: "center"
    },
    collapseIcon: {
        position: "absolute",
        left: "0px"
    }
}));

class MiddleExpandCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            listofcollapsedublicate:this.props.collapseForEntity.listOfCollapse,
            collapseentity:this.props.collapse,
            anchorEl: null,
            fieldAnchorEl: null,
            editData: null,
            left: 10,
            top: 10,
            isAdd: null,
            relationData: null,
            relationDataField: null,
            openCollapse: [],
            property: "",
            snackopen: false,
            snackmsg: "",
            snacktype: "success",
            isSave:true,
            isPublish:true,
            arr:[],
            saveopen:{open:false,savedata:null,savee:null},
            publishopen:{open:false,savedata:null,savee:null},
            zindex:Number(localStorage.getItem("zindex")),

        }
        this.handleClose = this.handleClose.bind(this)
        this.handleFieldClose = this.handleFieldClose.bind(this)
        this.mouseDown = this.mouseDown.bind(this)
        this.mouseMove = this.mouseMove.bind(this)
        this.mouseUp = this.mouseUp.bind(this)

    }
    componentDidMount(){
        let {length}=this.props
        // var arr = [];
        // console.log("leng",length.length)
      
        for (let i=1;i<=length?.length;i++){
       this.setState({arr:this.state.arr.push({1:i*100})}) 
        }
        this.setState({top:this.state.arr,})
        // console.log(this.state.editData,"openedit")
let {data}=this.props
// var idcollapse=data.attributes.id;

// this.setState({listofcollapsedublicate:idcollapse})

console.log("value",data.attributes.type.value)



    }
    componentDidUpdate( prevProps ,prevState){
        console.log("prevprops",prevProps.collapse,prevState.collapseentity)
                if(prevProps.collapse!==this.props.collapse){
                    this.setState({collapseentity:!this.state.collapseentity})
                    this.handleCollapse("","" ,this.props.collapse)
                }
        console.log("collapseentity",this.props.collapse)
            }

    closeSnackBar = () => {
        this.setState({ snackopen: false, open: false })
    }
    handleClose = () => {
        this.setState({ anchorEl: null, entityData: null, isAdd: null })
    }
    handleClick = (event, data) => {

        this.setState({ anchorEl: event, entityData: data })
    }
    handleFieldClose = () => {
        this.setState({ fieldAnchorEl: null, editData: null, isAdd: null })
    }
    handleFieldClick = (event) => {
        this.setState({ fieldAnchorEl: event })
    }
    handleRealatioShipClose = () => {
        this.setState({ relationshipAnchorEl: null })
    }
    handleRealatioShipClick = (event, relation) => {
        let context = this.context;
        let { handleRealatioShipOpen, data = {} } = this.props;
        handleRealatioShipOpen(event, data, relation)
    }

    handleGroupRealatioShipClose = () => {
        this.setState({ grouprelationshipAnchorEl: null })
    }
    handleGroupRealatioShipClick = (event, relation) => {
        let context = this.context;
        let { handleGroupRealatioShipOpen, data = {} } = this.props;
        handleGroupRealatioShipOpen(event, data, relation)
    }
    openEdit = (event, val, isAdd) => {
        this.setState({ fieldAnchorEl: event, editData: val, isAdd })
    }

    
    saveclose=()=>{
        this.setState({saveopen:{open:false}})
    }
    publishclose=()=>{
        this.setState({publishopen:{open:false}})
    }
    saveok=()=>{
        
        this.Save_Entity(this.state.saveopen.savee,this.state.saveopen.savedata)
        this.setState({saveopen:{open:false}})
    }

    publishok=()=>{
        
        this.Publish_Entity(this.state.publishopen.savee,this.state.publishopen.savedata)
        this.setState({publishopen:{open:false}})
    }
    saveokopen=(e,data)=>{
        this.setState({saveopen:{
            open:true,
            savedata:data,
            savee:e

        }})
    }

    publishokopen=(e,data)=>{
        this.setState({publishopen:{
            open:true,
            savedata:data,
            savee:e

        }})
    }
    mouseDown(e) {
        this.parentRef.style.zIndex = 50;
        this.hitOffset = {
            x: 132,
            y: 26,
            clientx: e?.clientX,
            clienty: e?.clientY
        };
        // this.canvasRef.addEventListener('mousemove', this.mouseMove);
        document.getElementsByClassName("IframeData")[0].contentDocument.addEventListener('mousemove', this.mouseMove);
        document.getElementsByClassName("IframeData")[0].contentDocument.addEventListener('mouseup', this.mouseUp);
        //   document.addEventListener('mousemove', this.mouseMove);
        // document.addEventListener('mouseup', this.mouseUp);

        // this.canvasRef.addEventListener('mouseup', this.mouseUp);
    }
    mouseMove(e) {
        // alert("the data")
        let { data = {}, } = this.props;
        let downx = this.hitOffset.clientx;
        let downy = this.hitOffset.clienty;
        if ((downx !== e.pageX) && (e.pageY !== downy)) {
            let classData = document.getElementsByClassName('IframeData')[0].contentWindow.document.getElementsByClassName("drag-entity" + data?.attributes?.id)[0]
            // let classData = document.getElementsByClassName("drag-entity" + data?.attributes?.id)[0]

            classData.style.left = (e.pageX - this.hitOffset.x) + "px";
            classData.style.top = (e.pageY - this.hitOffset.y) + "px";
            const { drawLine } = this.props;
            let { setCount, clearCount } = drawLine;
            setCount({
                ...drawLine,
                clearCount: clearCount + 1
            })
        }
    }
    mouseUp(e) {
        this.parentRef.style.zIndex = 1;
        const { drawLine } = this.props;
        let { setCount, count } = drawLine;
        let downx = this.hitOffset.clientx;
        let downy = this.hitOffset.clienty;
        let left = (e.pageX - this.hitOffset.x)
        let top = (e.pageY - this.hitOffset.y)
        if ((downx !== e.pageX) && (e.pageY !== downy)) {
            this.setToContext(left, top)
            setCount({
                ...drawLine,
                count: count + 1
            })
        }
        document.getElementsByClassName("IframeData")[0].contentDocument.removeEventListener('mousemove', this.mouseMove);
        document.getElementsByClassName("IframeData")[0].contentDocument.removeEventListener('mouseup', this.mouseUp);
        // document.removeEventListener('mousemove', this.mouseMove);
        // document.removeEventListener('mouseup', this.mouseUp);
    }
    setToContext(left, top) {
        let { leftContext, data } = this.props
        let { list, setEntity } = leftContext;
        list[data.attributes.type.value].map((val, i) => {
            if (val.attributes.id === data.attributes.id) {
                list[data.attributes.type.value][i].attributes["left"] = left
                list[data.attributes.type.value][i].attributes["top"] = top;
            }
            return false
        })
        setEntity({
            ...leftContext,
            list
        })
    }
    selectEntity(entity) {
        let context = this.context;
        // sessionStorage.setItem('metadataId',entity.metadataId);
        sessionStorage.setItem('entityCode', entity.entitycode);
        sessionStorage.setItem('entity', entity.entity);

    }
    deleteClick(e, data) {
        const { confirmationContext } = this.props;
        const { setConfirm } = confirmationContext;
        setConfirm({
            ...confirmationContext,
            open: true,
            title: "Confirmation",
            dialog: "Are you sure to delete this entity ?",
            type: 1,
            entity: data,
            data
        })
    }
    countFields = (list, countList = { _d: 0, _f: 0 }) => {

        list.map(dataSet => {
            
            if (dataSet.fieldType === 'd') {
                countList._d = countList._d + 1;
                if (dataSet?.properties?.fields) {
                    countList = this.countFields(dataSet?.properties?.fields, countList)
                } else if (dataSet?.fields) {
                    countList = this.countFields(dataSet?.fields, countList)

                }
            } else if (dataSet?.fieldType === 'f') {
                countList._f = countList._f + 1;

            }

            else if(["SourceSystemID" , "SourceSystemRefId"].includes(dataSet.name))
            {
                
                countList._f = countList._f + 1;

            }
            return false;
        })
        return countList;
    }
    handleCollapse = (id, action,allcolapse) => {

        debugger
        
        let newArr=this.state.zindex+1
            let { collapseForEntity } = this.props
            let { listOfCollapse, setListOfEntityCollaps } = collapseForEntity;
            // this.setState((prevState) =>({...this.state ,zindex:prevState.zIndex + 1}));
        // this.setState({...this.state, zIndex:this.state.zindex + 1})                // if(allcolapse){
    //     listOfCollapse=this.state.listofcollapsedublicate
    //     console.log("1stone")
    //     // console.log("ddddd",listOfCollapse);
    //     // console.log("state",this.state.listofcollapsedublicate)
    // }
        if (action === 'open') {
            listOfCollapse.push(id);
            let indexz=Number(localStorage.getItem("zindex"))
            localStorage.setItem('zindex',indexz+1)
            this.setState({...this.state , zindex:indexz})
        //    console.log("open",id)
            // this.setState({opencard:[...this.state.opencard,id]})
        } else if (action === 'close') {
            // const updatedeItems=this.state.opencard.filter(_filter => _filter !== id)
            listOfCollapse = listOfCollapse.filter(_filter => _filter !== id)
            var idd=id
            // let newArr = this.state.zindex.map(num => num + 1);
            
            let indexz=Number(localStorage.getItem("zindex"))
            // indexz++
            this.setState({opencard:idd})
           
            localStorage.setItem('zindex',indexz+1)
            // this.setState({ ...this.state.zindex,zindex: this.state.zindex + 1 });
          this.setState({...this.state , zindex:indexz})
            // this.props.collapse(id ,action)
             
           
            console.log("indexz",this.state.zindex,"",indexz)
            console.log("id",id)
           
            // listOfCollapse.push(id);
    
        }
        // console.table("1one",listOfCollapse)
        // console.log("indexz",newArr,"",)
        if(allcolapse){
            let {singleArray}=this.props
            // console.table("1one",listOfCollapse)
            // this.setState({listofcollapsedublicate:id})
            listOfCollapse=singleArray
            // console.log("2ones",listOfCollapse,)
            // if(data)
    
            // console.log("ddddd",listOfCollapse);
            // console.log("state",this.state.listofcollapsedublicate)
        }
            setListOfEntityCollaps({
                ...collapseForEntity,
                listOfCollapse
            });
            // console.log("handlecolll",listOfCollapse)
            // this.indexz()

        }
        // indexz=()=>{
        //     console.log("check",this.state.zindex)
        // }
    
    checkIndexFields = (field, index_ids, fieldpath = {}) => {
        ;

        for (let i = 0; i < field.length; i++) {
            if (!fieldpath[i]) {
                fieldpath[i] = { error: false, path: [] };

            }

            if (field[i].attributes !== undefined) {
                if (index_ids.indexOf(field[i].attributes.id) > -1) {
                    fieldpath[i].path.push(field[i].name);
                    fieldpath[i].error = true;

                    return fieldpath
                }
            }
            if (field[i].fieldType === 'd') {

                fieldpath[i].path.push(field[i].name);
                this.checkIndexFields(field[i].properties.fields, index_ids, fieldpath);
            }
        }
        return fieldpath
    }

    validatePath = [];
    is_diamention_error = false;
    is_Sequence_error = false;
    //is_groupBy_error = false;

    validateDiamention = (fields) => {
        for (let i = 0; i < fields.length; i++) {
            let pathData = this.fieldCheck(fields[i].fields);
            for (let pat in pathData) {
                if (pathData[pat].error) {
                    this.validatePath = pathData[pat].path
                    this.validatePath.unshift(fields[i].entity)
                }
            }
            if (this.is_diamention_error) {
                break;
            }

            else if (this.is_Sequence_error) {
                break;
            }
            // else if (this.is_groupBy_error) {
            //     break;
            //   }
        }
    }

    validateIndexs = (fields) => {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i]?.indexes?.length > 0) {
                let index_ids = fields[i].indexes.filter(_ => _.indexType.length <= 0).map(_m => _m.fieldId);
                ;

                let pathData = this.checkIndexFields(fields[i].fields, index_ids);
                ;

                let is_error = false;
                let errorPath = [];
                for (let pat in pathData) {
                    if (pathData[pat].error) {
                        errorPath = pathData[pat].path;
                        errorPath.unshift(fields[i].entity);
                        is_error = true;
                    }
                }
                if (is_error) {
                    return {
                        is_error: is_error,
                        path: errorPath
                    }
                }
            }

        }
        return {
            is_error: false,
            path: []
        }
    }

    fieldCheck = (field, fieldpath = [], parent = null) => {
        for (let i = 0; i < field.length; i++) {
            if (!parent) {
                fieldpath[i] = { error: false, path: [] };
                parent = i;
            }

            if (field[i].fieldType === 'd') {
                if (field[i].properties.fieldCollection === false) {
                    if (field[i].properties.isdimensionedge === true || field[i].properties.ref_entity.length > 0) {
                        fieldpath[parent].path.push(field[i].name);
                        this.fieldCheck(field[i].properties.fields, fieldpath, parent);
                    } else {
                        fieldpath[parent].error = true;
                        fieldpath[parent].path.push(field[i].name);
                        this.is_diamention_error = true;
                        break;
                    }
                } else {
                    fieldpath[parent].path.push(field[i].name);
                    this.fieldCheck(field[i].properties.fields, fieldpath, parent);

                }
                // this.validatePath[i] = fieldpath;

            }

            else if (field[i].properties.datatype === "string" || field[i].properties.datatype === "number" || field[i].properties.datatype === "integer" || field[i].properties.datatype === "positiveint" || field[i].properties.datatype === "unsignedint") {
          
                if (field[i].properties.Sequence !== undefined) {
                    if (field[i].properties.Sequence.IsReset === true) {
                        if (field[i].properties.Sequence.reset_config !== undefined) {
                            if (field[i].properties.Sequence.reset_config.resetType !== undefined) {
                                if (field[i].properties.Sequence.reset_config.resetType === "custom") {
                                    if (field[i].properties.Sequence.reset_config.resetvalue === "") {
                                        fieldpath[parent].error = true;
                                        fieldpath[parent].path.push(field[i].name);
                                        this.is_Sequence_error = true;
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
    
                // if(field[i].properties.Sequence !== undefined)
                // {
                //   if(field[i].properties.Sequence.groupBy !== undefined)
                //   {
                //     if([undefined , "" , null ].includes(field[i].properties.Sequence.groupBy.groupType) )
                //     {
                //       fieldpath[parent].error = true;
                //       fieldpath[parent].path.push(field[i].name);
                //       this.is_groupBy_error = true;
                //       break;
    
                //     }
    
                //     else if([undefined , "" , null ].includes(field[i].properties.Sequence.groupBy.groupQuery))
                //     {
                //       fieldpath[parent].error = true;
                //       fieldpath[parent].path.push(field[i].name);
                //       this.is_groupBy_error = true;
                //       break;
                //     }
                //   }
    
                  
                 
                // }
                
            }


        }
        return fieldpath
    }
    handleRename = () => {
        let { renameContext } = this.props;

        return new Promise(async (resolve, reject) => {

            if (renameContext.rename.length > 0) {

                let result = await Promise.all(renameContext.rename.map(_r => {
                    return axios
                        .post(`${environment.api_url}/api/rename_collection`, {
                            "db_name": _r.db_name,
                            "entity": _r.entity,
                            "renameEntity": _r.renameEntity,
                            "metadata_dbname": `${environment.database}`
                        })
                }))
                    .then(_d => {
                        renameContext.setRenameObj({
                            rename: []
                        });

                        resolve(true);

                    })

                    .catch((error) => {

                        resolve(false);

                    })
            } else {

                resolve(true);
            }

        })
    }



    Save_Entity = async (event, reletion) => {
        const { leftContext, relationshipcontext, collapseForEntity, grouprelationshipcontext } = this.props
        let { list, saveToPulishParams } = leftContext
        let values = Object.values(list)
        let datalist = []
        if (!values && values.length <= 0) {
            this.props.alert.setSnack({
                open: true,
                msg: "Please create entity widget",
                severity: 'info'
            });
            return;
        }
        values.map(val => {
            if (val) {
                datalist = [...datalist, ...val]
            }
            return false
        });

        datalist = datalist.filter(li => li.entity === reletion.entity);
        datalist = defaultFieldAdd(datalist);
        datalist = removeData(datalist);

        if (sessionStorage.getItem('is_db') === 'true') {
            let newrelationshipcontext = relationshipcontext.list.filter(li => li.entity === reletion.entity);
            let newgrouprelationshipcontext = grouprelationshipcontext.list.filter(li => li.entity === reletion.entity);
            let splitrelation = relationSplit(newrelationshipcontext)
            let splitgrouprelation = grouprelationSplit(newgrouprelationshipcontext)

            let payload = {
                client:
                    sessionStorage.clientName && sessionStorage.clientName !== 'undefined'
                        ? sessionStorage.clientName
                        : 'test',
                db_name:
                    sessionStorage.clientDB && sessionStorage.clientDB !== 'undefined'
                        ? sessionStorage.clientDB.replace(/\s/g, "_")
                        : 'test',
                clientId:
                    sessionStorage.clientId && sessionStorage.clientId !== 'undefined'
                        ? sessionStorage.clientId
                        : '',
                metadataId:
                    sessionStorage.getItem('metadataId') &&
                        sessionStorage.getItem('metadataId') !== 'undefined'
                        ? sessionStorage.getItem('metadataId')
                        : '',
                metadataname:
                    sessionStorage.getItem('metadataname') &&
                        sessionStorage.getItem('metadataname') !== 'undefined'
                        ? sessionStorage.getItem('metadataname')
                        : '',
                attributes: [{ relationship: splitrelation?.dontsend ?? [], listOfCollapse: collapseForEntity?.listOfCollapse ?? [] }],
                metadata: datalist,
                entity_relationship: splitrelation.send,

                entity_grouprelationship: splitgrouprelation.send,
                projectId:
                    sessionStorage.getItem('projectId') &&
                        sessionStorage.getItem('projectId') !== 'undefined'
                        ? sessionStorage.getItem('projectId')
                        : '',
                metadata_dbname: environment.database

            }

            if (datalist.length <= 0) {
                this.props.alert.setSnack({
                    open: true,
                    msg: "Please add entity",
                    severity: 'info',
                    vertical: 'top',
                    horizontal: 'right'
                });
                return;
            }

            this.validatePath = [];
            this.is_diamention_error = false;
            this.is_Sequence_error = false;
            //this.is_groupBy_error = false;
            this.validateDiamention(datalist)
            if (this.is_diamention_error) {
                this.props.alert.setSnack({
                    open: true,
                    msg: ` ${this.validatePath.join('/')} property dimension field ref entity should not be empty`,
                    severity: 'info',
                    vertical: 'top',
                    horizontal: 'right'
                });

                return;
            }

            else if (this.is_Sequence_error) {
                this.props.alert.setSnack({
                    open: true,
                    msg: `${this.validatePath.join('/')} Is Sequence custome date field cannot be empty `,
                    severity: 'info',
                    vertical: 'top',
                    horizontal: 'right'
                });

                return;
            }

            // else if (this.is_groupBy_error) {
         
            //     this.props.alert.setSnack({
            //         open: true,
            //         msg: `${this.validatePath.join('/')} Group Type or Group Query Cannot be empty`,
            //         severity: 'info'
            //     });
        
            //     return;
            // }

            let indexCheckFields = this.validateIndexs(datalist)

            if (indexCheckFields?.is_error) {
                this.props.alert.setSnack({
                    open: true,
                    msg: ` ${indexCheckFields.path.join('/')} index field should not be empty`,
                    severity: 'info',
                    vertical: 'top',
                    horizontal: 'right'
                });
                return;
            }
            let re_success = await this.handleRename();
            if (!re_success) {
                return;
            }
            let clone_pay = stringify(payload);
            clone_pay = { ...parse(clone_pay) };
            this.setState(prevState => ({
                ...prevState,
                isSave: false
            }))
            await axios
                .post(`${environment.api_url}/api/entity_saveschema`, clone_pay)
                .then(async res => {


                    if (res.data.Code === 201) {

                        let payloadentity =
                        {
                            entity: `${reletion.entity}`,
                            metadataId: `${sessionStorage.getItem("metadataId")}`,
                            metadata_dbname: `${environment.database}`
                        }

                        await axios
                            .post(`${environment.api_url}/api/entity_getschema`, payloadentity)
                            .then(response => {
                                if (response.data.Code === 201) {
                                    let data = converData(response.data)
                                    sessionStorage.setItem('metaId', response.data.Result.metadataId)
                                    sessionStorage.setItem('metadataId', response.data.Result.metadataId)

                                    sessionStorage.setItem(
                                        'metadataname',
                                        response.data.Result.metadataname
                                    )
                                    sessionStorage.setItem(
                                        'entityCode',
                                        response.data.Result.metadata[0].entitycode
                                    )
                                    sessionStorage.setItem(
                                        'entity',
                                        response.data.Result.metadata[0].entity
                                    )

                                    let { setEntity } = leftContext
                                    let { setRelation } = relationshipcontext;
                                    let { setgroupRelation } = grouprelationshipcontext;
                                    let { setListOfEntityCollaps } = collapseForEntity;

                                    const firstEntity = Object.keys(data.entity)[0];
                                    const firstEnttyField = data.entity[firstEntity];
                                    const Entitylist = leftContext.list[firstEntity].filter(li => li.entity !== reletion.entity);
                                    Entitylist.push(firstEnttyField[0]);
                                    var newEntityList = leftContext.list;
                                    delete newEntityList[firstEntity];
                                    newEntityList[`${firstEntity}`] = Entitylist;

                                    let EntityRelationshipcontext = relationshipcontext.list.filter(li => li.entity !== reletion.entity);
                                    if (data.relation !== undefined) {
                                        if (data.relation.length > 0) {
                                            data.relation.map(relation => {
                                                EntityRelationshipcontext.push(relation)
                                            })
                                        }
                                    }


                                    let EntityGroupRelationshipcontext = grouprelationshipcontext.list.filter(li => li.entity !== reletion.entity);

                                    if (data.grouprelation !== undefined) {
                                        if (data.grouprelation.length > 0) {

                                            data.grouprelation.map(relationss => {
                                                EntityGroupRelationshipcontext.push(relationss)
                                            })
                                        }
                                    }
                                    const datassss = leftContext.saveToPulishParams;


                                    const grp_rel = response?.data?.Result?.entity_grouprelationship;
                                    const rel = response?.data?.Result?.entity_relationship;
                                    const meta = response?.data?.Result?.metadata;

                                    if (grp_rel.length > 0) {
                                        datassss.entity_grouprelationship.filter(li => li.entity !== reletion.entity)
                                        grp_rel.map(relationshipgroup => {
                                            datassss.entity_grouprelationship.push(relationshipgroup);
                                        })
                                    }

                                    if (rel.length > 0) {
                                        datassss.entity_relationship.filter(li => li.entity !== reletion.entity)
                                        rel.map(relationship => {
                                            datassss.entity_relationship.push(relationship);
                                        })
                                    }

                                    if (meta.length > 0) {
                                        datassss.metadata.filter(li => li.entity !== reletion.entity)
                                        meta.map(metasss => {
                                            datassss.metadata.push(metasss);
                                        })
                                    }
                                    setEntity({
                                        ...leftContext,
                                        list: newEntityList,
                                        saveToPulishParamsElement: response?.data?.Result,
                                        saveToPulishParams: datassss

                                    })


                                    let setRelationship = EntityRelationshipcontext;
                                    if (response.data.Result.attributes && response.data.Result.attributes.length > 0) {
                                        let Multirelations = response.data.Result.attributes[0].relationship;
                                        setRelationship = [...setRelationship, ...Multirelations]
                                    }
                                    setRelation({
                                        ...relationshipcontext,
                                        list: setRelationship
                                    });


                                    let setgroupRelationship = EntityGroupRelationshipcontext;
                                    if (response.data.Result.attributes && response.data.Result.attributes.length > 0) {
                                        let Multirelations = response.data.Result.attributes[0].relationship;
                                        setgroupRelationship = [...setgroupRelationship, ...Multirelations]
                                    }
                                    setgroupRelation({
                                        ...grouprelationshipcontext,
                                        list: setgroupRelationship
                                    });
                                    setListOfEntityCollaps({
                                        ...collapseForEntity,
                                        listOfCollapse: response?.data?.Result?.attributes?.[0]?.listOfCollapse ?? []
                                    })

                                    this.props.alert.setSnack({
                                        open: true,
                                        msg: "entity saved successfully",
                                        severity: 'success',
                                        vertical: 'top',
                                        horizontal: 'right',
                                        
                                    });

                                    this.setState(prevState => ({
                                        ...prevState,
                                        isSave: true
                                    }))


                                }


                            })
                            .catch(err => {
                                this.props.alert.setSnack({
                                    open: true,
                                    msg: res.data.error,
                                    severity: 'error',
                                    vertical: 'top',
                                    horizontal: 'right',
                                    
                                });

                                this.setState(prevState => ({
                                    ...prevState,
                                    isSave: true
                                }))

                            })
                    } else {
                        this.props.alert.setSnack({
                            open: true,
                            msg: res.data.error,
                            severity: 'error',
                            vertical: 'top',
                            horizontal: 'right'
                        });

                        this.setState(prevState => ({
                            ...prevState,
                            isSave: true
                        }))
                    }
                })
                .catch(err => {
                    console.log("err", err)
                    this.props.alert.setSnack({
                        open: true,
                        msg: 'Entity is already exists ',
                        severity: 'error',
                        vertical: 'top',
                        horizontal: 'right'

                    })
                    this.setState(prevState => ({
                        ...prevState,
                        isSave: true
                    }))
                })
        }
    }

    Publish_Entity = async (event, reletion) => {

        

        const { leftContext, relationshipcontext, collapseForEntity, grouprelationshipcontext } = this.props;
        let { list, saveToPulishParamsElement, saveToPulishParams } = leftContext;

        if (saveToPulishParamsElement === undefined) {
            let metadata = saveToPulishParams.metadata.filter(li => li.entity === reletion.entity);
            let entity_relationship = saveToPulishParams.entity_relationship.filter(li => li.entity === reletion.entity);
            let entity_grouprelationship = saveToPulishParams.entity_grouprelationship.filter(li => li.entity === reletion.entity);

            saveToPulishParamsElement = {
                active: saveToPulishParams.active,
                activestatus: saveToPulishParams.activestatus,
                attributes: saveToPulishParams.attributes,
                client: saveToPulishParams.client,
                clientId: saveToPulishParams.clientId,
                createdate: saveToPulishParams.createdate,
                db_name: saveToPulishParams.db_name,
                entity_grouprelationship: entity_grouprelationship,
                entity_relationship: entity_relationship,
                metadata: metadata,
                metadataId: saveToPulishParams.metadataId,
                metadataname: saveToPulishParams.metadataname,
                projectId: saveToPulishParams.lastupdatetime,
                updatedate: saveToPulishParams.updatedate,
                updatedby: saveToPulishParams.updatedby,


            }
        }

        else if (saveToPulishParamsElement !== undefined) {

            if (saveToPulishParamsElement.metadata[0].entity === reletion.entity) {

                saveToPulishParamsElement = saveToPulishParamsElement
            }

            else {
                let metadata = saveToPulishParams.metadata.filter(li => li.entity === reletion.entity);
                let entity_relationship = saveToPulishParams.entity_relationship.filter(li => li.entity === reletion.entity);
                let entity_grouprelationship = saveToPulishParams.entity_grouprelationship.filter(li => li.entity === reletion.entity);

                saveToPulishParamsElement = {
                    active: saveToPulishParams.active,
                    activestatus: saveToPulishParams.activestatus,
                    attributes: saveToPulishParams.attributes,
                    client: saveToPulishParams.client,
                    clientId: saveToPulishParams.clientId,
                    createdate: saveToPulishParams.createdate,
                    db_name: saveToPulishParams.db_name,
                    entity_grouprelationship: entity_grouprelationship,
                    entity_relationship: entity_relationship,
                    metadata: metadata,
                    metadataId: saveToPulishParams.metadataId,
                    metadataname: saveToPulishParams.metadataname,
                    projectId: saveToPulishParams.lastupdatetime,
                    updatedate: saveToPulishParams.updatedate,
                    updatedby: saveToPulishParams.updatedby
                }

            }

        }
        this.setState(prevState => ({
            ...prevState,
            isPublish: false
        }))
        await axios
            .post(`${environment.api_url}/api/entity_publishschema`, { ...saveToPulishParamsElement, metadata_dbname: environment.database }).then(async (res) => {

                if (res.data.Code === 201) {

                    let payloadentity =
                    {
                        entity: `${reletion.entity}`,
                        metadataId: `${sessionStorage.getItem("metadataId")}`,
                        metadata_dbname: `${environment.database}`
                    }

                    await axios
                        .post(`${environment.api_url}/api/entity_getschema`, payloadentity)
                        .then(response => {
                            if (response.data.Code === 201) {
                                let data = converData(response.data)

                                let { setEntity } = leftContext
                                let { setRelation } = relationshipcontext;
                                let { setgroupRelation } = grouprelationshipcontext;
                                let { setListOfEntityCollaps } = collapseForEntity;

                                const firstEntity = Object.keys(data.entity)[0];
                                const firstEnttyField = data.entity[firstEntity];
                                const Entitylist = leftContext.list[firstEntity].filter(li => li.entity !== reletion.entity);
                                Entitylist.push(firstEnttyField[0]);
                                var newEntityList = leftContext.list;
                                delete newEntityList[firstEntity];
                                newEntityList[`${firstEntity}`] = Entitylist;

                                let EntityRelationshipcontext = relationshipcontext.list.filter(li => li.entity !== reletion.entity);
                                if (data.relation !== undefined) {
                                    if (data.relation.length > 0) {
                                        data.relation.map(relation => {
                                            EntityRelationshipcontext.push(relation)
                                        })
                                    }
                                }
                                let EntityGroupRelationshipcontext = grouprelationshipcontext.list.filter(li => li.entity !== reletion.entity);


                                if (data.grouprelation !== undefined) {
                                    if (data.grouprelation.length > 0) {

                                        data.grouprelation.map(relationss => {
                                            EntityGroupRelationshipcontext.push(relationss)
                                        })
                                    }
                                }
                                const datassss = leftContext.saveToPulishParams;


                                const grp_rel = response?.data?.Result?.entity_grouprelationship;
                                const rel = response?.data?.Result?.entity_relationship;
                                const meta = response?.data?.Result?.metadata;

                                if (grp_rel.length > 0) {
                                    datassss.entity_grouprelationship.filter(li => li.entity !== reletion.entity)
                                    grp_rel.map(relationshipgroup => {
                                        datassss.entity_grouprelationship.push(relationshipgroup);
                                    })
                                }

                                if (rel.length > 0) {
                                    datassss.entity_relationship.filter(li => li.entity !== reletion.entity)
                                    rel.map(relationship => {
                                        datassss.entity_relationship.push(relationship);
                                    })
                                }

                                if (meta.length > 0) {
                                    datassss.metadata.filter(li => li.entity !== reletion.entity)
                                    meta.map(metasss => {
                                        datassss.metadata.push(metasss);
                                    })
                                }

                                setEntity({
                                    ...leftContext,
                                    list: newEntityList,
                                    saveToPulishParams: datassss
                                    // saveToPulishParamsElement: response?.data?.Result,
                                })
                                let setRelationship = EntityRelationshipcontext;
                                if (response.data.Result.attributes && response.data.Result.attributes.length > 0) {
                                    let Multirelations = response.data.Result.attributes[0].relationship;
                                    setRelationship = [...setRelationship, ...Multirelations]
                                }
                                setRelation({
                                    ...relationshipcontext,
                                    list: setRelationship
                                });

                                let setgroupRelationship = EntityGroupRelationshipcontext;
                                if (response.data.Result.attributes && response.data.Result.attributes.length > 0) {
                                    let Multirelations = response.data.Result.attributes[0].relationship;
                                    setgroupRelationship = [...setgroupRelationship, ...Multirelations]
                                }
                                setgroupRelation({
                                    ...grouprelationshipcontext,
                                    list: setgroupRelationship
                                });
                                setListOfEntityCollaps({
                                    ...collapseForEntity,
                                    listOfCollapse: response?.data?.Result?.attributes?.[0]?.listOfCollapse ?? []
                                })

                                this.props.alert.setSnack({
                                    open: true,
                                    msg: 'entity Published successfully',
                                    severity: 'success',
                                    vertical: 'top',
                                    horizontal: 'right'
                                });
                                this.setState(prevState => ({
                                    ...prevState,
                                    isPublish: true
                                }))
                            }


                        })
                        .catch(err => {
                            this.props.alert.setSnack({
                                open: true,
                                msg: res.data.error,
                                severity: 'error',
                                vertical: 'top',
                                horizontal: 'right'
                            });

                            this.setState(prevState => ({
                                ...prevState,
                                isPublish: true
                            }))
                        })
                }
            })
    }


    render() {
        let context = this.context;
        let { classes, data = {}, tableIndex, collapseForEntity } = this.props;
        let { listOfCollapse } = collapseForEntity;
        let { _d, _f } = this.countFields(data?.fields);
        let { anchorEl, fieldAnchorEl, editData, entityData, isAdd , isSave , isPublish ,saveopen,publishopen,zindex,indexz} = this.state;
        console.log("zindexs",this.state.zindex)

        return (
            
<>



<React.Fragment>
<Grid
                    item
                    id="drag-drop-entity"
                    className={"drag-entity" + data?.attributes?.id}
                    ref={ref => {
                        this.parentRef = ref;
                    }}
                    style={{ 
                    width: "360px",
                     top:this.props.align?data.attributes.top:this.state.top[tableIndex]?.[1], 
                     left:this.props.align?data.attributes.left :data.attributes.type.label=="Personas"?"20px":data.attributes.type.label=="Things"?"400px":data.attributes.type.label=="Ecosystem"?"780px":data.attributes.type.label=="Products and Services"?"1160px":data.attributes.type.label=="Others"?"1542px":null,
                     position: "absolute", 
                     display:"inline-flex", 
                     zIndex:this.state.zindex?this.state.zindex:"50"
                     }}
                >

                    <Paper className={classes.paper}>

                        <Grid
                            ref={ref => {
                                this.canvasRef = ref;
                            }}
                            onMouseDown={(e) => this.mouseDown(e)}
                            className={classes.titleCard} style={{ background: data.attributes.type.label=="Personas"?"red":data.attributes.type.label=="Things"?"#BDBD4D":data.attributes.type.label=="Ecosystem"?"#5BBABE":data.attributes.type.label=="Products and Services"?"#186C8C":data.attributes.type.label=="Others"?"#0000FF":null }} onClick={() => this.selectEntity(data)}>
                            <Typography className={classes.title} >
                                {/* <Tooltip title="Field List Collapse"> */}
                                {/* {console.log(listOfCollapse,"listOfCollapse")} */}
                                <abbr title="Collapse" style={{ textDecoration: "none" }}>
                                    {listOfCollapse.indexOf(data?.attributes?.id) <= -1 ? <ArrowDropDownIcon onClick={() => this.handleCollapse(data?.attributes?.id, 'open')} className={classes.collapseIcon} /> : <ArrowRightIcon onClick={() => this.handleCollapse(data?.attributes?.id, 'close')} className={classes.collapseIcon} />}

                                </abbr>

                                {/* </Tooltip> */}
                                {
                                    data?.entity?.length > 9 ?
                                        <abbr title={data?.entity} style={{ textDecoration: "none" }}>
                                            &nbsp;&nbsp; {`${data?.entity?.slice(0, 9)}...`}
                                        </abbr>
                                        : <>
                                            &nbsp;&nbsp;{data.entity}
                                        </>

                                }
                                <abbr title="Edit" style={{ textDecoration: "none" }}>
                                    <EditOutlinedIcon className={'editIcon' + data?.attributes?.id + "  " + classes.entityEditIcon} onClick={e => this.handleClick(e, data)} />
                                </abbr>

                                <abbr title="Delete" style={{ textDecoration: "none" }}>
                                    <DeleteOutlineIcon className={'delete' + data?.attributes?.id + "  " + classes.entityEditIcon} onClick={e => this.deleteClick(e, data)} />
                                </abbr>
                                <abbr title={'Group Relationship'} style={{ textDecoration: "none" }}>

                              <ListAltIcon onClick={(e) => this.handleGroupRealatioShipClick(e, data)} className={classes.entityEditIcon + ' GrouprelationShip' + data?.attributes?.id} />
                               </abbr>
                                <abbr title={'Save'} style={{ textDecoration: "none" }}>
                                    <SaveAltIcon onClick={(e) => this.saveokopen(e, data)} className={classes.entityEditIcon + ' Save' + data?.attributes?.id} />
                                </abbr>
                                <abbr title={'Publish'} style={{ textDecoration: "none" }}>
                                    <PublishAltIcon onClick={(e) => this.publishokopen(e, data)} className={classes.entityEditIcon + ' Save' + data?.attributes?.id} />
                                </abbr>

                            </Typography>
                            <Typography className={classes.status} >{data.status == 'draft' ? 'Draft' : 'Published'}</Typography>
                        </Grid>
                        <Grid>
                           {/* {this.state.collapseentity?listOfCollapse.indexOf(data?.attributes?.id) <= -1 && data.fields && <EntityFieldList handleRealatioShipClick={this.handleRealatioShipClick} tableIndex={tableIndex} data={data} openEdit={this.openEdit} list={data.fields} />:null} */}
                           {this.state.collapseentity?listOfCollapse.indexOf(data?.attributes?.id) <= -1 && data.fields && <EntityFieldList handleRealatioShipClick={this.handleRealatioShipClick} tableIndex={tableIndex} data={data} openEdit={this.openEdit} list={data.fields}  />:null}
                        </Grid>

                        <Divider />
                        <Grid>
                            <ListCard list={[{
                                secondaryData: "Version", title: <>
                                    <Grid style={{ display: "flex" }}>
                                        <Typography className={'addEntityField' + data?.attributes?.id + ' ' + classes.addText} onClick={this.handleFieldClick} color="secondary">+ Add</Typography>
                                        <Grid style={{ marginLeft: "25px", display: "flex" }} >
                                            <Grid style={{ display: "flex" }}>
                                                <Typography>D</Typography>
                                                <Grid className={classes.notificationIcon}>
                                                    <Typography className={classes.notificationFont}>{_d}</Typography>
                                                </Grid>

                                            </Grid>
                                            <Grid style={{ display: "flex", marginLeft: "7px" }}>
                                                <Typography>F</Typography>
                                                <Grid className={classes.notificationIcon}>
                                                    <Typography className={classes.notificationFont}>{_f}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid> </>
                            }]} />
                        </Grid>
                    </Paper>

                </Grid>
                <CustomPopover open={Boolean(anchorEl)} classname={'editIcon' + data?.attributes?.id} target={anchorEl} onclose={this.handleClose}>
                    {Boolean(anchorEl) && <AddEntity editData={entityData} onclose={this.handleClose} />}
                </CustomPopover>
                {/* <Popover id={Boolean(fieldAnchorEl) ? 'simple-popover' : undefined} open={Boolean(fieldAnchorEl)} classname={'addEntityField' + (editData ? editData?.attributes?.id : data?.attributes?.id)} anchorEl={fieldAnchorEl} onClose={this.handleFieldClose}>
                    <React.Fragment>
                        {Boolean(fieldAnchorEl) && <AddField isAdd={isAdd} editData={editData} data={data} onclose={this.handleFieldClose} />}
                    </React.Fragment>
                </Popover> */}
                <CustomPopover open={Boolean(fieldAnchorEl)} classname={'addEntityField' + (editData ? editData?.attributes?.id : data?.attributes?.id)} target={fieldAnchorEl} onclose={this.handleFieldClose}>
                    <React.Fragment>
                        {Boolean(fieldAnchorEl) && <AddField isAdd={isAdd} editData={editData} data={data} onclose={this.handleFieldClose} />}
                    </React.Fragment>
                </CustomPopover>
                {/* <CustomPopover open={Boolean(relationshipAnchorEl)} classname={'relationShip' + (relationData ? relationData?.attributes?.id : data?.attributes?.id)} target={relationshipAnchorEl} onclose={this.handleRealatioShipClose}>
                </CustomPopover> */}

            {/* </div> */}
            
            </React.Fragment>
            
            <Dialog open={saveopen.open} onClose={this.saveclose}>
        <DialogTitle style={{paddingTop:"15px",paddingLeft:"78px"}}>SAVE</DialogTitle>
        <DialogContent>
        <DialogContentText style={{paddingLeft:"40px"}}id="alert-dialog-slide-description">
           Do you want save
          </DialogContentText>
        </DialogContent>
         <List sx={{ pt: 0 }}>
        
        <ListItem
          autoFocus button>
           {/* <Button  variant="outlined" onClick={this.saveclose}>Cancel</Button>
          <Button style={{marginLeft:"12px"}} color="success" variant="outlined" onClick={this.saveok}>ok</Button> */}
          <Stack direction="row" spacing={2}>
        <Button variant="contained" color="error"onClick={this.saveclose} >
            Cancel
        </Button>
         <Button variant="contained" color="success" onClick={this.saveok}>
        Ok
      </Button>
    </Stack>
        </ListItem>
      </List>
       
      </Dialog>

      <Dialog open={publishopen.open} onClose={this.publishclose}>
        <DialogTitle style={{paddingTop:"15px",paddingLeft:"55px"}}>PUBLISH</DialogTitle>
        <DialogContent>
        <DialogContentText style={{paddingLeft:"22px"}}id="alert-dialog-slide-description">
           Do you want publish
          </DialogContentText>
        </DialogContent>
         <List sx={{ pt: 0 }}>
        
        <ListItem
          autoFocus button >
    <Stack direction="row" spacing={2}>
      <Button variant="contained" color="error"onClick={this.publishclose} >
        Cancel
      </Button>
      <Button variant="contained" color="success" onClick={this.publishok}>
        Ok
      </Button>
    </Stack>
        </ListItem>
      </List>
       
      </Dialog>
            

 

</>


        )
    }
}
MiddleExpandCard.contextType = RightSideNav;
export default withStyles(useStyles)(withAllContexts(MiddleExpandCard));