import React from "react";
import { makeStyles, Grid, Paper, Typography, Avatar } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Appbar from "../components/appbar";

const useStyles = makeStyles((theme) => ({
  root1: {
    flexGrow: 1,
    width: "100%",
    height: "46px",
    lineHeight: "46px",
    alignItems: "center",
  },
  content: {
    width: "100%",
  },
  paper: {
    borderRadius: 0,
  },
  title: {
    padding: "5px 10px",
    color: "#0b78fa",
    // color: "#5d5df7"
  },
  username: {
    fontSize: 14,
    fontWeight: 600,
    margin: "auto",
  },
  rounded: {
    width: "22px",
    height: "22px",
    fontSize: "11px",
    margin: "auto",
    background: "black",
    color: "blue",
  },
  rightalign: {
    textAlign: "right",
    display: "grid",
    gridTemplateColumns: "36px 105px 26px",
    float: "right",
    padding: "8px",
  },
  textName: {
    background: "#0a78fa",
    width: "102px",
    textAlign: "center",
    /* top: 13px; */
    marginTop: "2px",
    color: "white",
    fontSize: "13px",
  },
  TextValue: {
    paddingLeft: "10px",
  },
}));

const withNavBars = (Component) => (props) => {
  console.log("withnavbars", Component);
  const classes = useStyles();
  debugger;
  return (
    <div>
      <Appbar />
      <div className={classes.content}>
        <Component {...props}>{props.children}</Component>
      </div>
    </div>
  );
};

export default withNavBars;
