/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020
 * @desc All the environment variables are taken from process.env and exported
 * via Config variable.
 */

import dotenv from "dotenv";
dotenv.config();

let environment = {};

// environment.api_url = `${process.env.REACT_APP_SECURITY}://${process.env.REACT_APP_DOMAINNAME}:${process.env.REACT_APP_PORT}`;
environment.api_url = process.env.REACT_APP_ARANGO_DOMAIN_NAME;
environment.graphql = process.env.REACT_APP_GQL_ENDPOINT;
environment.socket = process.env.REACT_APP_SOCKET;
environment.qdm_property_entity = process.env.REACT_APP_QDMPROPERTIES_ENTITY;
environment.database = process.env.REACT_APP_DATABASE;
environment.qdm_enterprisemodeler_entity =
  process.env.REACT_APP_ENTERPRISEMODELER_ENTITY;
environment.qdm_qpas_entity = process.env.REACT_APP_QPAS_ENTITY;
environment.entity_template = process.env.REACT_APP_ENTITY_TEMPLATE;
environment.qdm_admin_url = process.env.REACT_APP_QDM_ADMIN_ENDPOINT;

export default environment;
