/**
 * @author Kumaravel Pazhani
 * @email kumaravel@qdmplatforms.com
 * @create 12/9/2020
 * @modify 12/11/2020
 * @desc Exporting all the components from /src/components
 */

import React from 'react'
import { Grid, Paper, Avatar } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { MiniCard, ExpandCard } from '../../../components'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
const useStyles = theme => ({
  root: {
    flexGrow: 1,
    display: 'grid',
    gridTemplateColumns: '1fr 28px'
  },
  paper: {
    // paddingBottom:'20px',
    minHeight: 'calc(100vh - 90px)',
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  avatar: {
    width: '30px',
    height: '30px',
    position: 'absolute',
    marginTop: '10px',
    marginLeft: 5,
    background: 'white',
    border: '1px solid #e4e4e4',
    color: 'black',
  },
  afterIcon: {
    fontSize: '15px'
  }
})

class LeftCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    let { classes, expandLeftCard, left } = this.props
    // let maxSize = left ? { marginLeft: '47px' } : { marginLeft: '0px' }
    let maxSize = { marginLeft: '0px' }
    return (
      <Grid container className={classes.root} >
        {/* <Grid container > */}
          <Grid item md={12}  style={{    overflow: "scroll", height: "calc(100vh - 82px)"}}>
            <Paper className={classes.paper}>
              {!left ? (
                <MiniCard expandLeftCard={expandLeftCard} />
              ) : (
                <ExpandCard />
              )}
            </Paper>
          </Grid>
        {/* </Grid> */}
     
        {left && (
          <Grid item>
            <Avatar
              onClick={() => {
                expandLeftCard()
              }}
              className={classes.avatar}
            >
              {!left ? (
                <ArrowForwardIosIcon className={classes.afterIcon} />
              ) : (
                <NavigateBeforeIcon />
              )}
            </Avatar>
          </Grid>
        )}
      </Grid>
    )
  }
}

export default withStyles(useStyles)(LeftCard)
